import { DASHBOARD_SIDEBAR_LINKS } from 'lib/constants'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { SidebarLink } from './components/SidebarLink'

export const Sidebar = () => {
  const [collapse, setCollapse] = useState(true)
  return (
    <div
      onMouseEnter={() => setCollapse(false)}
      onMouseLeave={() => setCollapse(true)}
      className={twMerge(
        'flex shrink-0 flex-col bg-white px-2 py-4',
        'w-14 transition-all duration-300 ease-in-out hover:w-60'
      )}
    >
      <Link to="/">
        <div className="m-1 flex items-center gap-2 px-1 py-1">
          <img className="mr-1 h-12 shrink-0" src="/icons/logo.png" alt="silmaril" />
          {!collapse && (
            <div className="mb-2 mt-auto">
              <img className="h-5" src="/icons/silmaril.png" alt="silmaril" />
            </div>
          )}
        </div>
      </Link>
      <div className="flex flex-1 flex-col gap-1 py-4">
        {DASHBOARD_SIDEBAR_LINKS.map((link) => (
          <SidebarLink key={link.key} link={link} droplist={link.dropable} collapse={collapse} />
        ))}
      </div>
    </div>
  )
}
