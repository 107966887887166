import { Form } from './components/Form'
import { OutputSection } from './components/OutputSection'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { cleanSelectedFiles } from 'app/features/fileSlice'

export const MainContent = () => {
  const dispatch = useDispatch()
  const { outputData } = useSelector((state) => state.loop)

  useEffect(() => {
    dispatch(cleanSelectedFiles())
  }, [dispatch])

  return (
    <div className="flex h-full w-full shrink grow basis-0 justify-center overflow-y-auto">
      <div className="h-full w-3/4">{Object.keys(outputData).length === 0 ? <Form /> : <OutputSection />}</div>
    </div>
  )
}
