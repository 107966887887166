import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { deleteTask, getTaskList } from 'api/Task/action/action'
import { editText } from 'app/features/counterSlice'
import {
  addPreset as addKnowledgePreset,
  deletePreset as deleteKnowledgePreset,
  togglePresetSelection as toggleKnowledgePresetSelection
} from 'app/features/loopTasksSlice'
import {
  addPreset as addMirrorPreset,
  deletePreset as deleteMirrorPreset,
  togglePresetSelection as toggleMirrorPresetSelection
} from 'app/features/mirrorTasksSlice'
import {
  addPreset as addSmartChatPreset,
  deletePreset as deleteSmartChatPreset,
  togglePresetSelection as toggleSmartChatPresetSelection
} from 'app/features/smartChatTasksSlice'
import { AddTaskModal } from 'components/shared/Modal/AddTaskModal'
import { ASSISTANT, LOOP, MIRROR } from 'lib/constants/BookMarkConst'

export const Tasks = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [toggle, setToggle] = useState(false)

  const handleChange = (text) => {
    dispatch(editText(text))
  }

  let slice
  let addAction, deleteAction, toggleAction
  let workspace_selected
  const smartloc = useSelector((state) => state.smartChatTasks)
  const looploc = useSelector((state) => state.loopTasks)
  const mirrorloc = useSelector((state) => state.mirrorTasks)

  if (pathname === '/playground/assistant' || pathname === '/playground') {
    slice = smartloc
    workspace_selected = ASSISTANT
    addAction = addSmartChatPreset
    deleteAction = deleteSmartChatPreset
    toggleAction = toggleSmartChatPresetSelection
  } else if (pathname === '/playground/loop') {
    slice = looploc
    workspace_selected = LOOP
    addAction = addKnowledgePreset
    deleteAction = deleteKnowledgePreset
    toggleAction = toggleKnowledgePresetSelection
  } else if (pathname === '/playground/mirror') {
    slice = mirrorloc
    workspace_selected = MIRROR
    addAction = addMirrorPreset
    deleteAction = deleteMirrorPreset
    toggleAction = toggleMirrorPresetSelection
  }

  const [showAddPresetModal, setShowAddPresetModal] = useState(false)

  const handleAddPresetCancel = () => {
    setShowAddPresetModal(false)
  }

  const handleDeletePreset = async (id) => {
    const response = await dispatch(deleteTask(id))
    if (response.status !== 204) return toast.error("Couldn't delete it right now")
    toast.success('deleted Item successfully')
    setToggle(!toggle)
  }

  const handleAddPreset = () => {
    setToggle(!toggle)
    setShowAddPresetModal(false)
  }

  const togglePresetSelection = (index) => {
    dispatch(toggleAction(index))
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getTaskList())
      if (response.status === 200) {
        const tasksWithIsSelected = response.data.tasks
          .filter((task) => task.workspace === workspace_selected)
          .map((task) => ({
            ...task,
            selected: false
          }))

        // Dispatch the modified tasks
        dispatch(addAction(tasksWithIsSelected))
        return
      }
    }
    // setWorkspace(workspace_selected)
    fetchData()
  }, [toggle])

  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <img src="/icons/down.png" alt="logo" className="h-4 w-4 object-cover" />
          <p className="big">Tasks</p>
        </div>
        <button onClick={() => setShowAddPresetModal(true)} className="small cursor-pointer text-[#3163E4]">
          + Add
        </button>
      </div>
      <div className="mb-8 mt-2 shrink grow basis-0 overflow-y-auto">
        <div className="flex flex-col pb-2">
          {slice && slice.presets && slice.presets.length > 0 ? (
            slice.presets.map((preset, index) => (
              <div
                key={index}
                className={`my-1 mr-2 flex items-center rounded p-2 text-sm hover:bg-blue-100 ${
                  preset.selected ? 'bg-[#E6EDFF]' : ' bg-[#FBFBFB] hover:bg-[#E6EDFF]'
                } group`}
              >
                <div
                  className="flex w-full cursor-pointer flex-col items-start justify-start"
                  onClick={() => {
                    togglePresetSelection(index)
                    handleChange(preset.data)
                  }}
                >
                  <p className="big text-[#383838]">{preset.title}</p>
                  <p className="small  text-[#6C6C6C]">{preset.data}</p>
                </div>
                <button
                  className="ml-auto flex items-center justify-center opacity-0 group-hover:opacity-100"
                  onClick={async (e) => {
                    e.stopPropagation()
                    handleDeletePreset(preset.id)
                  }}
                >
                  <img className="h-4 w-4" src="/icons/bin.png" alt="Bin" />
                </button>
              </div>
            ))
          ) : (
            <p className="mt-2 indent-1 text-xs">No presets available</p>
          )}
        </div>

        <AddTaskModal
          onSave={handleAddPreset}
          onCancel={handleAddPresetCancel}
          workspace={workspace_selected}
          showModal={showAddPresetModal}
        />
      </div>
    </>
  )
}
