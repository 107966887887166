import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { LoopTutorialModal } from './components/LoopTutorialModal'
import { MirrorTutorialModal } from './components/MirrorTutorialModal'
import { AssistantTutorialModal } from './components/AssistantTutorialModal'

export const TutorialModal = () => {
  const { pathname } = useLocation()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <button
        className="m-2 flex rounded bg-[#F5F6FA] px-2 py-2 text-sm hover:bg-blue-100"
        onClick={() => {
          setShowModal(true)
        }}
      >
        <img className="mr-2 h-5 w-5" src="/icons/tutorial.png" alt="logo" />
        <p className="big">Tutorials</p>
      </button>

      <AssistantTutorialModal setShowModal={setShowModal} showModal={showModal && pathname.includes('/assistant')} />
      <LoopTutorialModal setShowModal={setShowModal} showModal={showModal && pathname.includes('/loop')} />
      <MirrorTutorialModal setShowModal={setShowModal} showModal={showModal && pathname.includes('/mirror')} />
    </>
  )
}
