import { downloadThreadPDF } from 'api/SmartAssistant/action/action'
import { setPdfStatus } from 'app/features/smartChatSlice'
import { TextareaWithButton } from 'components/shared/Inputs/TextareaWithButton'
import { DefaultModal } from 'components/shared/Modal/DefaultModal'
import { PlaygroundButtons } from 'pages/playground/components/PlaygroundButtons'
import { useDownloadPDF } from 'pages/playground/hooks/useDownloadPDF'
import { useState } from 'react'
import { useSelector } from 'react-redux'

export const FooterActions = ({ onSubmit }) => {
  const { downloadPDF } = useDownloadPDF()
  const { pdfStatus, data, threadIdRedux, isLoading } = useSelector(({ smartChat }) => smartChat)
  const { selectedFiles } = useSelector(({ files }) => files)
  const [isShowedNoFileModal, setIsShowedNoFileModal] = useState(false)

  const disabledButton = pdfStatus === 'generating' || data.length === 0 || isLoading
  const tooltipMsg = isLoading ? 'Waiting to fetching data...' : 'No available'

  const handleOnSubmitTextarea = () => {
    if (selectedFiles.length === 0) {
      setIsShowedNoFileModal(true)
      return
    }
    onSubmit()
  }

  const handleOnSubmitModal = () => {
    setIsShowedNoFileModal(false)
    onSubmit()
  }

  return (
    <div className="w-full">
      <PlaygroundButtons
        disabledButton={disabledButton}
        tooltipMsg={tooltipMsg}
        pdfStatus={pdfStatus}
        downloadPDF={() =>
          downloadPDF({ id: threadIdRedux, setPdfStatus, downloadAction: downloadThreadPDF, fileName: 'Scout' })
        }
        workspaceId={threadIdRedux}
      />

      <div className="mx-1 flex flex-col items-center px-3 pb-2">
        <TextareaWithButton handleOnClick={() => handleOnSubmitTextarea()} />
      </div>

      <DefaultModal
        isVisible={isShowedNoFileModal}
        onClose={() => setIsShowedNoFileModal(false)}
        onSubmit={() => handleOnSubmitModal()}
        title="Generate answer"
        size="s"
      >
        <span>No file selected. Do you want to continue?</span>
      </DefaultModal>
    </div>
  )
}
