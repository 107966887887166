import { useDownloadFile } from 'hooks/useDownloadFile'
import React from 'react'

export const DetailsSidebarDownload = ({ id, name, type }) => {
  const { downloadFile } = useDownloadFile()

  return (
    <div className="small mr-5 mt-3 flex w-full items-center justify-between rounded border border-gray-300 px-4 py-3">
      <span className="left-text cursor-default">{name}</span>
      <button className="right-text cursor-pointer text-[#3244E8]" onClick={() => downloadFile({ name, type, id })}>
        Download
      </button>
    </div>
  )
}
