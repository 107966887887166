import * as api from './index'
import { mapper } from './utils/mapper'
export const postLoop = (data) => async () => {
  try {
    const response = await api.postLoop(mapper(data))

    if (response.status < 300) {
      return { status: response.status, data: response.data }
    }
  } catch (error) {
    if (error.response) {
      return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const postProcessLoop = (id) => async () => {
  try {
    const response = await api.postProcessLoop(id)

    if (response.status < 300) {
      return { status: response.status, data: response.data }
    }
  } catch (error) {
    if (error.response) {
      return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const getLoop = (id) => async () => {
  try {
    const response = await api.getLoop(id)

    if (response.status < 300) {
      return { status: response.status, data: response.data }
    }
  } catch (error) {
    if (error.response) {
      return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}
