import { useSelector } from 'react-redux'
import { Buttons } from './components/Buttons'
import { ExpandableInput } from './components/ExpandableInput'

export const FooterActions = ({ onSubmit }) => {
  const { isListVisible } = useSelector((state) => state.mirror)

  return (
    <div className="flex w-full items-center justify-center">
      <div className={`w-4/5 ${isListVisible ? 'mt-4 rounded-lg border bg-white' : 'no-border'}`}>
        <div className="mx-2 mt-2 pb-2 ">
          <Buttons />
          <ExpandableInput onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  )
}
