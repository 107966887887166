import { downloadPDF as downloadPDFMirror } from 'api/Mirror/action/action'
import { setPdfStatus } from 'app/features/mirrorSlice'
import { PlaygroundButtons } from 'pages/playground/components/PlaygroundButtons'
import { useDownloadPDF } from 'pages/playground/hooks/useDownloadPDF'
import { useSelector } from 'react-redux'

export const Buttons = () => {
  const { downloadPDF } = useDownloadPDF()
  const { pdfStatus, mirrorIdRedux, isListVisible, generatedResponses, isLoading } = useSelector(
    (state) => state.mirror
  )

  const disabledButton = pdfStatus === 'generating' || generatedResponses.length === 0 || isLoading
  const tooltipMsg = isLoading ? 'Waiting to fetching data...' : 'No available'

  return (
    <>
      {!isListVisible && (
        <PlaygroundButtons
          disabledButton={disabledButton}
          tooltipMsg={tooltipMsg}
          pdfStatus={pdfStatus}
          downloadPDF={() =>
            downloadPDF({ id: mirrorIdRedux, setPdfStatus, downloadAction: downloadPDFMirror, fileName: 'Mirror' })
          }
          workspaceId={mirrorIdRedux}
        />
      )}
    </>
  )
}
