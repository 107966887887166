import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { CleanDataModal } from './CleanDataModal'
import { TutorialModal } from './TutorialModal/TutorialModal'

const SECTIONS = ['assistant', 'loop', 'mirror']

const getLinks = (pathname) =>
  SECTIONS.map((section) => {
    const path = `/playground/${section}`
    return (
      <Link
        key={section}
        to={path}
        className={twMerge(
          'm-1 mt-1 flex rounded px-3 py-2 text-black no-underline hover:bg-blue-100 hover:no-underline',
          pathname === path ? 'rounded bg-blue-100' : 'bg-gray-100'
        )}
      >
        <p className="text-sm capitalize leading-5">{section === 'assistant' ? 'scout' : section}</p>
      </Link>
    )
  })

export const Header = () => {
  const { pathname } = useLocation()
  //TODO: ADD for loop
  // const { pdfStatus } = useSelector(({loop}) => loop)
  const { pdfStatus: pdfStatusAssistant } = useSelector(({ smartChat }) => smartChat)
  const { pdfStatus: pdfStatusMirror } = useSelector(({ mirror }) => mirror)

  return (
    <div className="flex flex-col">
      <div className="flex justify-between border-b p-2">
        <div className="flex items-center justify-center">{getLinks(pathname)}</div>

        <div className="flex shrink-0">
          <CleanDataModal />
          <TutorialModal />
        </div>
      </div>

      {/* TODO: REVIEW THIS -> Progress bar */}
      <div className="h-2">
        {(pdfStatusAssistant === 'generating' || pdfStatusMirror === 'generating') && (
          <div className="demo-container">
            <div className="progress-bar">
              <div className="progress-bar-value"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
