import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  payload: {},
  outputData: {},
  isLoading: false
}

const loopSlice = createSlice({
  name: 'loop',
  initialState,
  reducers: {
    setPayload: (state, action) => {
      state.payload = action.payload
    },
    addData: (state, action) => {
      state.data = action.payload
    },
    addOutputData: (state, action) => {
      state.outputData = action.payload
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    resetLoop: () => initialState
  }
})

export const { setPayload, addData, addOutputData, setIsLoading, resetLoop } = loopSlice.actions
export default loopSlice.reducer
