import { Tooltip } from 'react-tooltip'
import { useRandomID } from '../../../hooks/useRandomId'
import { twMerge } from 'tailwind-merge'

export const ReactTooltip = ({
  tooltipContent,
  ariaLabel,
  children,
  className,
  enabled = true,
  place = 'bottom',
  opacity = 0.9,
  positionStrategy = 'absolute',
  ...rest
}) => {
  const { generateRandomID } = useRandomID()
  const id = generateRandomID()

  return enabled ? (
    <>
      <div id={id} aria-label={ariaLabel} className={twMerge('inline-block', className)}>
        {children}
      </div>
      <Tooltip
        clickable
        anchorSelect={`#${id}`}
        opacity={opacity}
        place={place}
        positionStrategy={positionStrategy}
        className="max-w-52"
        {...rest}
      >
        {tooltipContent}
      </Tooltip>
    </>
  ) : (
    <>{children}</>
  )
}
