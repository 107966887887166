import { addQuestion, postMirror } from 'api/Mirror/action/action'
import { arraysAreEqual } from 'api/Mirror/action/check'
import { addGeneratedResponse, addTask, setIsListVisible, setMirrorIdRedux } from 'app/features/mirrorSlice'
import { removeSelectedPresets, toggleOffPresetSelection } from 'app/features/mirrorTasksSlice'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { AnswerList } from './AnswerList'
import { FooterActions } from './FooterActions/FooterActions'

export const CompareDocuments = () => {
  const dispatch = useDispatch()
  const { tasks } = useSelector((state) => state.mirror)
  const { presets } = useSelector((state) => state.mirrorTasks)
  const { selectedFiles, files } = useSelector((state) => state.files)

  const [isFirstPost, setIsFirstPost] = useState(true)
  const [mirrorId, setMirrorId] = useState(null)
  const [selectedFileDuplicate, setSelectedFileDuplicate] = useState([])

  useEffect(() => {
    const selectedPreset = presets.filter((preset) => preset.selected)
    if (selectedPreset.length > 0) {
      selectedPreset.forEach((i) => {
        if (!tasks.includes(i)) {
          dispatch(addTask(i))
          dispatch(toggleOffPresetSelection(i.id))
        }
      })
      dispatch(setIsListVisible(true))
    }
  }, [presets])

  const postData = async () => {
    var localMirrorId = mirrorId
    const selectedFilesToUpload = selectedFiles.map((index) => files[index])
    if (isFirstPost || !arraysAreEqual(selectedFileDuplicate, selectedFilesToUpload)) {
      const response = await dispatch(postMirror(undefined, files[selectedFiles[0]], files[selectedFiles[1]]))

      if (response.status === null || response.status > 201) {
        toast.error(response.data)
        return
      }
      localMirrorId = response.data.id
      setMirrorId(response.data.id)
      dispatch(setMirrorIdRedux(response.data.id))
      setIsFirstPost(false)
      setSelectedFileDuplicate([...selectedFilesToUpload])
    }
    if (localMirrorId == null) return

    const taskPromises = tasks.map(async (task) => {
      const response = await dispatch(addQuestion(localMirrorId, task.data))
      if (!response || response.status > 201) {
        toast.error(response.data)
        return null
      }
      return response
    })
    const responses = await Promise.all(taskPromises)
    responses.forEach((response) => {
      if (response) {
        dispatch(addGeneratedResponse(response.data))
      }
    })
    dispatch(removeSelectedPresets())
  }

  return (
    <div className="flex h-full flex-col justify-between">
      <AnswerList />
      <FooterActions onSubmit={postData} />
    </div>
  )
}
