import { ReactTooltip } from 'components/shared/Tooltip/ReactTooltip'
import { Spinner } from 'components/shared/Loaders'
import { DefaultButton } from 'components/shared/Buttons/DefaultButton'
import { useState } from 'react'
import { AddBookmarkModal } from 'components/shared/Modal/AddBookmarkModal'
import { useLocation } from 'react-router-dom'
import { WORKSPACE_TYPE } from 'lib/constants/BookMarkConst'

const getIconButton = (pdfStatus) =>
  pdfStatus === 'generating' ? (
    <Spinner className="mx-0 size-4 text-blue-700" />
  ) : (
    <img className="h-5" src="/icons/download.png" alt="generate-pdf" />
  )

const getWorkspaceTypeByPath = (pathname) =>
  Object.keys(WORKSPACE_TYPE).find((key) => pathname.includes(key.toLowerCase()))

export const PlaygroundButtons = ({ disabledButton, tooltipMsg, pdfStatus, downloadPDF, workspaceId }) => {
  const { pathname } = useLocation()
  const [showBookMarkModal, setShowBookMarkModal] = useState(false)

  return (
    <>
      <div className="flex items-center justify-center">
        <div className="flex gap-2 p-4">
          <ReactTooltip enabled={disabledButton} tooltipContent={tooltipMsg} place="top">
            <DefaultButton onClick={() => downloadPDF()} disabled={disabledButton}>
              <div className="flex items-center justify-center gap-2">
                {getIconButton(pdfStatus)}
                <span className={`big ${pdfStatus === 'generating' ? ' text-blue-700' : 'text-[#555555]'}`}>
                  {pdfStatus === 'generating' ? 'Generating PDF' : 'Generate PDF'}
                </span>
              </div>
            </DefaultButton>
          </ReactTooltip>

          <ReactTooltip enabled={disabledButton} tooltipContent={tooltipMsg} place="top">
            <DefaultButton
              onClick={() => setShowBookMarkModal(true)}
              disabled={disabledButton}
              label="Bookmark"
              icon="/icons/bookmark.svg"
            >
              <div className="flex items-center justify-center gap-2">
                <img className="h-5" src="/icons/bookmark.svg" alt="logo" />
                <p className="big text-[#555555]">Bookmark</p>
              </div>
            </DefaultButton>
          </ReactTooltip>
        </div>
      </div>

      <AddBookmarkModal
        isVisible={showBookMarkModal}
        onCancel={() => setShowBookMarkModal(false)}
        workspace_type={getWorkspaceTypeByPath(pathname)}
        workspace_id={workspaceId}
      />
    </>
  )
}
